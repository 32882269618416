import React from 'react'
import Layout from '../../components/Layout'
import HeaderMeta from '../../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  PageNameBox,
  TitleBox,
  LinkBox,
  HeadingBox,
} from "@minware/ui-minware";

const header = {
  title: `Thank you`,
  description: `Thank you for submitting your contact information.`,
  canonicalUrl: '/working-agreement/submitted',
}
const link = 'https://docs.google.com/document/d/1L3QXh7fjqWD79GSk6RauHLTAKEqTeDT_VVDf-mkwHLY/edit?usp=sharing';

export default () => (
  <Layout desktopBgImageName="none">
    <HeaderMeta header={header}/>
    <ResponsiveMaxWidthFrame maxWidth="500px">
        <SectionGroup>
          <PageNameBox>Working Agreement</PageNameBox>
          <TitleBox>
            Thank you for your submision
          </TitleBox>
          <HeadingBox level="h3">
            Here is a link to minware's example
            working agreement template:
          </HeadingBox>
          <LinkBox link={link} size="large">Working Agreement Template Document</LinkBox>
        </SectionGroup>
    </ResponsiveMaxWidthFrame>
  </Layout>
)
